import React from 'react';
import { useRouter } from 'next/router';
import T3Iframe from './t3Iframe/T3Iframe';
import { onAccountRewardsPageView } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export const HutRewardsLearnMore = (): JSX.Element => {
  const router = useRouter();
  const analytics = useAnalytics();
  const { pr, co } = router.query;
  analytics.push(() => onAccountRewardsPageView());

  return (
    <>
      <T3Iframe
        loggedin="n"
        store_loyalty="y"
        flogin="/account"
        pr={getStringOrFirstElement(pr)}
        co={getStringOrFirstElement(co)}
      />
    </>
  );
};

export function getStringOrFirstElement(stringOrArray?: string | string[]): string | undefined {
  if (typeof stringOrArray === 'string') {
    return stringOrArray;
  }
  return stringOrArray?.length ? stringOrArray[0] : undefined;
}

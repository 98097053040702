import React from 'react';
import { GetServerSideProps, NextPage } from 'next';
import { HutRewardsLearnMore } from '@/rewards/HutRewardsLearnMore';
import Header from '@/header';
import CenteredContainer from '@/common/CenteredContainer';
import Footer from '@/footer';
import initializePage from '@/common/pageSetup';

const JoinPage: NextPage = () => (
  <>
    <Header />
    <CenteredContainer>
      <HutRewardsLearnMore />
    </CenteredContainer>
    <Footer />
  </>
);

export const getServerSideProps: GetServerSideProps = async (ctx) => initializePage(ctx, 'join', false, false);

export default JoinPage;
